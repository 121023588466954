import Layout from '@components/layout/Layout';
import Container from '@components/ui/container/Container';
import Footer from '@components/ui/footer/Footer';
import Header from '@components/ui/header/Header';
import HighlightsList from '@components/ui/highlights-list/HighlightsList';
import TitleBar from '@components/ui/title-bar/TitleBar';
import LanguageProvider from '@lib/language.provider';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const PrawoKonsumenckiePage = ({ pageContext, location }) => {
	i18n.activate(pageContext.language);

	const data = [
		t`Przygotowywanie projektów wzorców umownych (umowy, regulaminy, regulaminy promocji).`,
		t`Doradztwo w projektowaniu procesów sprzedażowych w obrocie konsumenckim z uwzględnieniem specyfiki umów zawieranych na odległość i poza lokalem przedsiębiorstwa.`,
		t`Analizowanie, czy stosowane umowy i regulaminy nie zawierają sprzecznych z prawem postanowień, jak również przy wdrażaniu rozwiązań nie godzących w chronione prawem interesy konsumentów.`,
		t`Doradzam w postępowaniach przed Urzędem Ochrony Konkurencji i Konsumentów w zakresie postępowań dotyczących naruszenia zbiorowych interesów konsumentów oraz stosowania niedozwolonych klauzul umownych.`
	];

	const highlights = [
		{
			label: t`Doświadczenie`,
			description: t`Dzięki zdobytemu doświadczeniu i ugruntowanej wiedzy potrafimy starannie ułożyć relacje handlowe z konsumentami`
		},
		{
			label: t`3`,
			description: t`pozytywnie przeprowadzone postępowania w trybie art. 28 ust. 1 ustawy o ochronie konkurencji i konsumentów`
		},
		{
			label: t`Ponad 20`,
			description: t`pozytywnie zakończonych dla przedsiębiorcy postępowań dotyczących naruszenia zbiorowych interesów konsumentów`
		}
	];

	return (
		<LanguageProvider>
			<Layout>
				<main>
					<title>
						<Trans>Prawo konsumenckie</Trans>
					</title>
					<Header location={location.pathname} />
					<div className="flex flex-col min-h-screen">
						<TitleBar>
							<div className="relative z-10 pt-10 w-full text-white md:max-w-md lg:max-w-2xl md:pr-10 lg:pr-20">
								<div className="text-sm uppercase">
									<Trans>Specjalizacje</Trans>
								</div>
								<h1 className="font-serif text-4xl font-bold">
									<Trans>Prawo konsumenckie</Trans>
								</h1>
							</div>
							<div className="absolute top-0 left-0 w-full h-full">
								<StaticImage
									className="block object-cover w-full h-full"
									src="../../assets/images/fuzje-i-przejecia-photo-01.jpg"
									alt={t`Kontakt`}
									placeholder={'none'}
								/>
							</div>
						</TitleBar>
						<div>
							<Container>
								<div className="grid gap-8 lg:gap-16 py-8 md:grid-cols-2 md:py-12">
									<div className="text-gray-dark">
										<h2 className="mb-3 font-bold">Zakres usług</h2>
										<HighlightsList items={data} />
									</div>
									<div>
										<div className="grid gap-2 lg:gap-3">
											{highlights.map((item) => (
												<div className="p-4 lg:p-8 border border-gray-medium">
													<div className="w-full">
														<div className="mb-3 text-2xl font-bold">
															{item.label}
														</div>
														<div className="text-gray-dark text-sm">
															{item.description}
														</div>
													</div>
												</div>
											))}
										</div>
									</div>
								</div>
							</Container>
						</div>
						<Footer />
					</div>
				</main>
			</Layout>
		</LanguageProvider>
	);
};

export default PrawoKonsumenckiePage;
